import React from 'react';
//import logo from './logo.svg';
//import { Counter } from './features/counter/Counter';
import './App.css';
import { HeaderExampleImageProp } from './components/header';
import ListExampleIcon from './components/list';
import MenuExampleStackable from './components/nav';
import { Grid } from 'semantic-ui-react';
import { ItemFormNew } from './components/item-form/new';

function App() {
  return (
    <div className="App">
      <Grid centered columns={12}>
        <Grid.Column width={12}>
          <HeaderExampleImageProp></HeaderExampleImageProp>
          <MenuExampleStackable></MenuExampleStackable>
          <ListExampleIcon></ListExampleIcon>
          <button class="ui icon left labeled button"><i aria-hidden="true" class="plus icon"></i>Add</button>
          <ItemFormNew></ItemFormNew>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default App;
