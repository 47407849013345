import React from 'react';
import { Form } from 'semantic-ui-react';

export class ItemFormNew extends React.Component {

    constructor(props) {
        super();
        this.props = props;
    }

    render() {
        return (
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='Item Title' placeholder='Item title' />
                </Form.Group>
                <Form.TextArea label='Description' placeholder='What is this Item about ...' />
                <Form.Button>Submit</Form.Button>
            </Form>
        );
    }

}