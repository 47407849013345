import React from 'react'
import { Header } from 'semantic-ui-react'

export const HeaderExampleImageProp = () => (
    <Header as='h2'>
        Goals - <em>Apple Watch</em>
        <Header.Subheader>
            Manage your goals with ease on your Watch.
        </Header.Subheader>
    </Header>
)

